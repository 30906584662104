import { WorkflowType } from '@/domain/super-admin/constants';
import uniqBy from 'lodash.uniqby';
import RoleType from '../types/RoleType';

/**
 * Extract list of user roles from a given workflow
 * by iterating on the workflow steps
 * @param {*} workflow
 */
export const extractRolesFromWorkflow = (workflow) => {
  const roles = uniqBy(workflow.steps, 'approverRole').map(
    (item) => item.approverRole
  );

  if (workflow.type === WorkflowType.ORDER_SUBMISSION) {
    roles.push(RoleType.CUSTOMER_ACCOUNT_MANAGER);
  }

  return roles;
};

export const extractRolesFromWorkflows = (workflows) => {
  let steps = [];
  for (const workflow of workflows) {
    steps = steps.contact(workflow.steps);
  }

  return uniqBy(steps, 'approverRole').map((item) => item.approverRole);
};
